import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Startseite | Qyvyfiy Autozentrum
			</title>
			<meta name={"description"} content={"Unser Unternehmen stützt sich auf die gesammelte Erfahrung und das tiefe Verständnis der Automobilindustrie, und wir sind stolz darauf, die Entwicklung von Trends und Technologien in der Welt des Automobils zu verfolgen."} />
			<meta property={"og:title"} content={"Startseite | Qyvyfiy Autozentrum"} />
			<meta property={"og:description"} content={"Unser Unternehmen stützt sich auf die gesammelte Erfahrung und das tiefe Verständnis der Automobilindustrie, und wir sind stolz darauf, die Entwicklung von Trends und Technologien in der Welt des Automobils zu verfolgen."} />
			<meta property={"og:image"} content={"https://qyvyfiy.com/img/home-1.jpeg"} />
			<link rel={"shortcut icon"} href={"https://qyvyfiy.com/img/icon.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://qyvyfiy.com/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://qyvyfiy.com/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://qyvyfiy.com/img/icon.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://qyvyfiy.com/img/icon.png"} />
			<meta name={"msapplication-TileImage"} content={"https://qyvyfiy.com/img/icon.png"} />
			<meta name={"msapplication-TileColor"} content={"https://qyvyfiy.com/img/icon.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="100px 0 130px 0" background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/643d8e6ebf72b300207d7233/images/16447966_v960-ning-05.jpg?v=2023-05-16T18:45:46.530Z) 0% 0% /cover repeat scroll padding-box" md-padding="80px 0 90px 0" quarkly-title="Hero-9">
			<Box
				min-width="100px"
				min-height="100px"
				lg-width="100%"
				display="flex"
				flex-direction="column"
				align-items="center"
			>
				<Text
					margin="0px 0px 20px 0px"
					font="normal 900 65px/1.2 --fontFamily-sans"
					sm-font="normal 700 42px/1.2 --fontFamily-sans"
					padding="0px 200px 0px 200px"
					text-align="center"
					color="--darkL1"
					lg-padding="0px 0 0px 0"
					as="h1"
				>
					Qyvyfiy Autozentrum
				</Text>
				<Text
					margin="0px 0px 50px 0px"
					font="--lead"
					color="#50555a"
					padding="0px 200px 0px 200px"
					text-align="center"
					lg-padding="0px 0 0px 0"
				>
					Qyvyfiy Autozentrum Centre ist nicht einfach nur eine Tankstelle, sondern ein Ort, an dem die Leidenschaft für Autos zum Leben erwacht. Unser Unternehmen stützt sich auf die gesammelte Erfahrung und das tiefe Verständnis der Automobilindustrie, und wir sind stolz darauf, die Entwicklung von Trends und Technologien in der Welt des Automobils zu verfolgen.
				</Text>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					sm-flex-direction="column"
					sm-align-self="stretch"
				>
					<Button
						margin="0px 15px 0px 0px"
						padding="12px 28px 12px 28px"
						background="#3f24d8"
						border-radius="8px"
						font="normal 400 17px/1.5 --fontFamily-sans"
						sm-margin="0px 0 15px 0px"
						transition="background-color 0.2s ease 0s"
						border-width="2px"
						border-style="solid"
						border-color="#3f24d8"
						hover-background="rgba(63, 36, 216, 0)"
						hover-color="--darkL1"
						type="link"
						href="/kontakt"
						text-decoration-line="initial"
						sm-text-align="center"
						sm-display="inline-block"
						sm-align-self="center"
					>
						Kontaktieren Sie uns
					</Button>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				padding="0px 40px 0px 0px"
				border-radius="24px"
				margin="0px 0px 0px 0px"
				width="30%"
				lg-padding="0px 20px 0px 0px"
				lg-width="50%"
				md-width="100%"
				md-padding="0px 0px 0px 0px"
				display="flex"
				flex-direction="column"
				align-items="flex-start"
				md-margin="0px 0px 60px 0px"
				md-align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Unsere Vorteile
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					font="--base"
					color="--greyD3"
					md-text-align="center"
					md-margin="0px 0px 20px 0px"
				>
					Wir sind nicht nur bestrebt, Ihr Auto zu verschönern, sondern auch ein einzigartiges Erlebnis für Sie zu schaffen und Ihnen Komfort und Unterhaltung zu bieten, während Sie warten. Im Qyvyfiy Autozentrum Centre achten wir auf jedes Detail und arbeiten daran, dass Ihr Auto die perfekte Verkörperung Ihrer Leidenschaft für die Welt des Automobils wird.
				</Text>
			</Box>
			<Box
				display="flex"
				lg-flex-wrap="wrap"
				width="70%"
				flex-direction="column"
				lg-width="50%"
				md-width="100%"
			>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 30px 0px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--darkL2"
						width="49px"
						height="49px"
						background="--color-lightD1"
						sm-margin="0px 14px 0px 0px"
					>
						1
					</Text>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Kompetenz und Erfahrung
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							Unser Spezialistenteam verfügt über umfangreiche Erfahrung und fundierte Kenntnisse im Bereich Fahrzeugtuning und -wartung. Wir entwickeln uns ständig weiter und verfolgen die neuesten Trends, um Ihnen die fortschrittlichsten Lösungen anbieten zu können.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 30px 0px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--darkL2"
						width="49px"
						height="49px"
						background="--color-lightD1"
						sm-margin="0px 14px 0px 0px"
					>
						2
					</Text>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Individueller Ansatz
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							Wir schätzen die Einzigartigkeit eines jeden Kunden und Fahrzeugs. Wir arbeiten eng mit Ihnen zusammen, um Ihre Bedürfnisse zu verstehen und Lösungen anzubieten, die Ihren Erwartungen und Ihrem Budget entsprechen.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 30px 0px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--darkL2"
						width="49px"
						height="49px"
						background="--color-lightD1"
						sm-margin="0px 14px 0px 0px"
					>
						3
					</Text>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Kundenspezifische Auspuffanlagen
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							Unsere Fähigkeit, einzigartige und leistungsstarke Auspuffanlagen zu entwickeln, macht uns zu einem führenden Anbieter von Audio-Tuning und Exterieur-Styling.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-4">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				width="65%"
				justify-content="center"
				align-items="flex-start"
				margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				lg-width="100%"
				padding="16px 16px 16px 16px"
				sm-margin="0px 0px 30px 0px"
			>
				<Box
					width="100%"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 125% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						bottom={0}
						min-height="100%"
						top={0}
						left={0}
						position="absolute"
						display="block"
						width="100%"
						right={0}
						src="https://qyvyfiy.com/img/home-1.jpeg"
						object-fit="cover"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				align-items="flex-start"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				width="35%"
				flex-direction="column"
				lg-align-items="center"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					padding="0px 0px 65% 0px"
					width="100%"
					height="auto"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						right={0}
						bottom="0px"
						object-fit="cover"
						position="absolute"
						display="block"
						width="100%"
						top="auto"
						min-height="100%"
						src="https://qyvyfiy.com/img/home-2.jpeg"
					/>
				</Box>
				<Text
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					margin="24px 0px 48px 0px"
					md-text-align="left"
				>
					Wir engagieren uns für kontinuierliche Verbesserung und Innovation. Unsere Werkstatt ist mit modernen Geräten und Werkzeugen ausgestattet, die es uns ermöglichen, auch die komplexesten Projekte zu realisieren. Bei DriveCraft können Sie hervorragende Qualität, Zuverlässigkeit und eine grenzenlose Leidenschaft für die Welt des Automobils erwarten.
				</Text>
				<Box
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					padding="0px 0px 65% 0px"
				>
					<Image
						display="block"
						width="100%"
						top="auto"
						bottom="0px"
						min-height="100%"
						src="https://qyvyfiy.com/img/home-3.jpeg"
						object-fit="cover"
						position="absolute"
						left={0}
						right={0}
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Qyvyfiy Autozentrum Centre ist Ihr Partner, wenn es darum geht, das perfekte Auto zu kreieren, das auf der Straße auffällt, Ihre Persönlichkeit und Ihren Stil zum Ausdruck bringt und natürlich ein unvergessliches Fahrerlebnis bietet.
				</Text>
			</Box>
		</Section>
		<Section padding="60px 0" sm-padding="40px 0" color="--dark">
			<Text as="h1" font="--headline1" margin="0 0 24px 0">
				Häufig gestellte Fragen (FAQ)
			</Text>
			<Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="33.333%" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text as="h3" font="--headline3" margin="12px 0">
							Wie lange dauert es, bis die Arbeiten abgeschlossen sind?
						</Text>
						<Text as="p" font="--base" margin="12px 0" color="--greyD2">
							Die Dauer hängt von der jeweiligen Dienstleistung ab. Unsere Fachleute werden Sie während des Beratungsgesprächs genau informieren.
						</Text>
					</Box>
				</Box>
				<Box width="33.333%" padding="16px 16px 16px 16px" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text as="h3" font="--headline3" margin="12px 0">
							Was ist das Besondere an Ihrem Unternehmen?
						</Text>
						<Text as="p" font="--base" margin="12px 0" color="--greyD2">
							Wir haben uns auf Tuning, individuelle Auspuffanlagen und Fahrzeugwartung spezialisiert. Unser Expertenteam garantiert hohe Qualität und einen individuellen Ansatz für jeden Kunden.
						</Text>
					</Box>
				</Box>
				<Box width="33.333%" padding="16px 16px 16px 16px" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text as="h3" font="--headline3" margin="12px 0">
							Geben Sie eine Garantie für die durchgeführten Arbeiten?
						</Text>
						<Text as="p" font="--base" margin="12px 0" color="--greyD2">
							Ja, wir gewähren eine Garantie auf unsere Arbeit und die verwendeten Teile. Unser Ziel ist Ihr Wohlbefinden und Ihre Sicherheit.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});